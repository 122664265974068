import * as React from 'react';

import { Article, ArticleNode, Edge, MyTheme } from '../types/types';
import injectSheet, { CSSProperties, StyleCreator, WithSheet } from 'react-jss/lib/injectSheet';

import ArticleBar from '../components/ArticleBar';
import ArticleGrid from '../components/ArticleGrid';
import Button from '../components/Button';
import { Footer } from '../components/Footer';
import HelmetComponent from '../components/Helmet';
import { Link } from '@reach/router';
import PageHeader from '../components/PageHeader';
import Spacer from '../components/Text/Spacer';
import Subscribe from '../components/Subscribe/SubscribeBanner';
import { graphql } from 'gatsby';

type OwnProps = {
    data: {
        post: Article;
        allPosts: Edge<ArticleNode>;
    };
};

/*
 *  dangerouslySetInnerHTML
 *  https://github.com/gatsbyjs/gatsby/issues/4454
 *  https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
 */
const ProjectTemplate = ({ data, classes }: WithSheet<StyleCreator<string, any>> & OwnProps) => {
    const { post, allPosts } = data;

    const headerData = {
        background: post.featured_image,
        title: post.title
    };

    return (
        <div>
            <HelmetComponent title={post.seo_title} description={post.meta_description} />
            <PageHeader data={headerData} />
            <div className={classes.main}>
                <ArticleBar />
                <div className={classes.container}>
                    <div className={classes.content}>
                        <Spacer />
                        {/* <h1 className={classes.title}>{post.title}</h1> */}
                        {/* <Spacer /> */}
                        <div
                            className={classes.externalContent}
                            dangerouslySetInnerHTML={{
                                __html: post.body
                            }}
                        />
                        <Spacer />
                        <h1 className={classes && classes.insights}>Insights</h1>
                        <ArticleGrid articles={allPosts} />
                        <Link className={classes && classes.nav} to="/insight">
                            <Button>See more</Button>
                        </Link>
                        <Spacer />
                    </div>
                </div>
                <Subscribe />
                <Spacer />
                <Footer />
            </div>
        </div>
    );
};

const styles = (theme: MyTheme): Record<string, CSSProperties> => ({
    main: {
        marginTop: '70vh',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 2,
        backgroundColor: theme.palette.basic.white
    },
    container: theme.content,
    content: {
        margin: `0px ${theme.basePadding}px`,
        backgroundColor: theme.palette.basic.white
    },
    title: {
        textAlign: 'center'
    },
    externalContent: {
        ...theme.narrowContent,
        textAlign: 'justify',
        margin: '0px auto',
        overflow: 'hidden',
        width: '100%',
        '& p': {
            margin: `${theme.basePadding}px 0`,
            '& img': {
                maxWidth: '100%',
                height: 'auto'
            },
            '& span': {
                '& img': {
                    maxWidth: '100%',
                    height: '100%',
                    marginTop: 2 * theme.basePadding,
                    marginBottom: 2 * theme.basePadding
                }
            },
            '& b': {
                fontWeight: 'bold'
            }
        },
        '& h3': {
            marginTop: 2 * theme.basePadding,
            '& strong': {
                fontWeight: 900
            },
            '& b': {
                fontWeight: 900
            }
        },
        '& h5': {
            '& span': {
                fontWeight: 'bold'
            }
        },
        '& h2': {
            '& strong': {
                fontWeight: 'bold'
            },
            '& b': {
                fontWeight: 'bold'
            },
            '& span': {
                '& b': {
                    fontWeight: 'bold'
                }
            }
        },
        '& figure': {
            textAlign: 'center',
            '& figcaption': {
                fontStyle: 'italic'
            }
        },
        '& ul': {
            '& li': {
                margin: `${theme.basePadding}px 0`
            }
        },
        '& a': {
            color: theme.palette.vortexa.primary
        }
    },
    insights: {
        fontSize: theme.typography.sizes.h1,
        width: '30%',
        borderTop: `1px solid ${theme.palette.fog.primary}`,
        padding: '2% 0 0 ',
        margin: '3% auto',
        justifyContent: 'center',
        display: 'flex'
    },
    nav: {
        textAlign: 'center'
    }
});

export default injectSheet(styles)(ProjectTemplate);

export const pageQuery = graphql`
    query($slug: String!) {
        post: butterPost(slug: { eq: $slug }) {
            ...Article
        }

        allPosts: allButterPost(limit: 3, sort: { fields: published, order: DESC }, filter: { slug: { ne: $slug } }) {
            ...Articles
        }
    }
`;
